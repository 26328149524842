function initDropdowns() {
	$('.styled-select').each(function() {
		var nativeSelect = $($(this).data('native'));
		var selector = this;
		var displayValue = $(this).children('span');

		$(this).click(function(e) {
			e.preventDefault();
			e.stopPropagation();
			$(this).toggleClass('active');
		});

		$(window).click(function(e) {
			$(selector).removeClass('active');
		});

		$(this).find('a').each(function() {
			$(this).click(function(e) {
				e.preventDefault();
				e.stopPropagation();
				$(selector).removeClass('active');
				$(nativeSelect).children('option').attr('selected', false);
				$(nativeSelect).find("option[value='" + $(this).data('value') + "']").attr("selected", true);
				$(displayValue).text($(this).text());
			});
		});

		$(nativeSelect).change(function() {
			$(displayValue).text($(nativeSelect).find('option:selected').text());
		});
	});
}

function initMobileView() {
	$(".menu-button a").click(function(e) {
		e.preventDefault();

		var icon = $(this).find("i")

		if ($(icon).hasClass("fa-bars")) {
			$(icon).removeClass('fa-bars');
			$(icon).addClass('fa-times');
			$("#main-menu").addClass("open");
		} else {
			$(icon).addClass('fa-bars');
			$(icon).removeClass('fa-times');
			$("#main-menu").removeClass("open");
		}
	});
}

function initScripts() {
	initDropdowns();
	initMobileView();
}